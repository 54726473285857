// https://medium.com/@mweststrate/mobx-4-better-simpler-faster-smaller-c1fbc08008da

import BondsmanStore from './BondsmanStore'
import CaseStore from './CaseStore'
import DefendantStore from './DefendantStore'
import DocumentStore from './DocumentStore'
import FinancesStore from './FinancesStore'
import OfficesStore from './OfficesStore'
import UserStore from './UserStore'
import WarehouseStore from './WarehouseStore'

const stores = {
  bondsmanStore: new BondsmanStore(),
  caseStore: new CaseStore(),
  defendantStore: new DefendantStore(),
  documentStore: new DocumentStore(),
  financesStore: new FinancesStore(),
  officesStore: new OfficesStore(),
  userStore: new UserStore(),
  warehouseStore: new WarehouseStore()
}

export default stores
