import ReactGA from 'react-ga'

const displayLogs = (process.env.REACT_APP_BUILD !== 'production')

const _logIt = message => {
  if (displayLogs) console.info(`[TRACKING]`, message) // eslint-disable-line
}

const init = () => {
  _logIt(`Activating GA: ${process.env.REACT_APP_GA}`)
  ReactGA.initialize(process.env.REACT_APP_GA)
  return
}

const pageView = () => {
  _logIt(`Pageview Sent: ${window.location.pathname + window.location.search}`)
  return ReactGA.pageview(window.location.pathname + window.location.search)
}

const gaEvent = (category, action, value = null) => {
  _logIt(`Event Sent: (C)${category} (A)${action} (V)${value}`)
  return ReactGA.event({ category, action, value })
}

const notFoundEvent = () => {
  gaEvent('Error', 'Not Found Event', window.location.pathname)
}

const frontDoorEvent = () => {
  gaEvent('Register', 'Front Door')
}
const stateSelectEvent = value => {
  gaEvent('Register', 'State', value)
}
const countySelectEvent = county => {
  const isSearchableText = county.isSearchable ? 'SEARCHABLE' : 'NOT SEARCHABLE'
  gaEvent('Register', 'County', `${county.name} - ${isSearchableText}`)
}
const lookupEvent = value => {
  gaEvent('Register', 'Lookup', value)
}
const noResultsEvent = value => {
  gaEvent('Register', 'No Results', value)
}
const tryAgainEvent = value => {
  gaEvent('Register', 'Try Again', value)
}
const seventyTwoHours = value => gaEvent('Register', 'Seventy Two Hours', value)
const forgotToken = () => gaEvent('Register', 'Forgot Token')
const defendantDetails = (inmate, charge) => {
  gaEvent('Register', 'Arrestee Selected', `${inmate.firstName} ${inmate.lastName} - ${charge.bookingNumber}`)
}
const defendantFound = value => {
  gaEvent('Register', 'Arrestee Found', value)
}
const userRegistered = params => {
  gaEvent('Register', 'User Registered Type', params.communication_preference)
}
const magicPageView = value => {
  gaEvent('Register', 'Magic Landing Page View', value)
}
const magicLink = value => {
  gaEvent('Register', 'Magic Link Clicked', value)
}
const magicUrlLink = value => {
  gaEvent('Link', 'Magic URL Used', value)
}
const magicEmployeeLink = value => {
  gaEvent('Register', 'Magic Employee Link', value)
}
const bondsmanView = value => {
  gaEvent('User', 'Bondsman View', value)
}
const bondsmanSelect = value => {
  gaEvent('User', 'Bondsman Selected', value)
}
const quoteAccepted = (params = {}) => {
  gaEvent('User', 'Quote Accepted')
}
const paymentInfoClicked = params => {
  gaEvent('User', 'Payment Info Viewed')
}
const paymentInfoEntered = params => {
  gaEvent('User', 'Payment Info Entered', `${params.card_type} - ${params.amount}`)
}
const paymentInfoConfirmed = (params = {}) => {
  gaEvent('User', 'Payment Info Confirmed')
}
const defendantPageViewed = (params = {}) => {
}

const questionairFinished = (params = {}) => {
  gaEvent('User', 'Questionair Finished')
}

const donateEvent = value => gaEvent('User', 'Donate', value)

const bondsmanQuickAdd = params => {
  gaEvent('Register', 'Quick Add', params.bondsman)
}
const userNoteEvent = (params = {}) => {
}

const documentCenterUpdated = section => {
  gaEvent('User', 'Documents Updated', section)
}

const documentsFinished = (params = {}) => {
  gaEvent('User', 'Documents Finished')
}

const documentsSigned = (params = {}) => {
  gaEvent('User', 'Documents Signed')
}

const vars = {
  init,
  pageView,
  frontDoorEvent,
  stateSelectEvent,
  countySelectEvent,
  lookupEvent,
  noResultsEvent,
  tryAgainEvent,
  seventyTwoHours,
  forgotToken,
  defendantDetails,
  defendantFound,
  userRegistered,
  magicPageView,
  magicEmployeeLink,
  magicLink,
  magicUrlLink,
  questionairFinished,
  bondsmanView,
  bondsmanSelect,
  donateEvent,
  defendantPageViewed,
  bondsmanQuickAdd,
  quoteAccepted,
  paymentInfoClicked,
  paymentInfoEntered,
  paymentInfoConfirmed,
  userNoteEvent,
  documentCenterUpdated,
  documentsFinished,
  documentsSigned,
  notFoundEvent
}

export default vars