import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { withAuthenticationRequired } from '@auth0/auth0-react'

import Loadable from 'react-loadable'
import Loading from 'components/Loading'

import Callback from 'layouts/Callback'

const Home = Loadable({
  loader: () => import('layouts/Home'),
  loading: Loading
})

const AlphaDashboard = Loadable({
  loader: () => import('layouts/AlphaDashboard'),
  loading: Loading
})

const Activation = Loadable({
  loader: () => import('layouts/Activation'),
  loading: Loading
})

const Login = Loadable({
  loader: () => import('layouts/Login'),
  loading: Loading
})

const LearningCenter = Loadable({
  loader: () => import('layouts/LearningCenter'),
  loading: Loading
})

const Receipt = Loadable({
  loader: () => import('layouts/Receipt'),
  loading: Loading
})

const MagicLink = Loadable({
  loader: () => import('layouts/MagicLink'),
  loading: Loading
})

const MagicEmployee = Loadable({
  loader: () => import('layouts/MagicEmployee'),
  loading: Loading
})

const Magic = Loadable({
  loader: () => import('layouts/Magic'),
  loading: Loading
})

const MagicSign = Loadable({
  loader: () => import('layouts/MagicSign'),
  loading: Loading
})

const MagicPayment = Loadable({
  loader: () => import('layouts/MagicPayment'),
  loading: Loading
})

const MagicPlanPayment = Loadable({
  loader: () => import('layouts/MagicPlanPayment'),
  loading: Loading
})

const MagicCode = Loadable({
  loader: () => import('layouts/MagicCode'),
  loading: Loading
})

const SecureUpload = Loadable({
  loader: () => import('layouts/SecureUpload'),
  loading: Loading
})

const Lookup = Loadable({
  loader: () => import('layouts/Lookup'),
  loading: Loading
})

const Register = Loadable({
  loader: () => import('layouts/Register'),
  loading: Loading
})

const Example = Loadable({
  loader: () => import('layouts/Example'),
  loading: Loading
})

const NotFound = Loadable({
  loader: () => import('layouts/NotFound'),
  loading: Loading
})

const AgentRegister = Loadable({
  loader: () => import('layouts/AgentRegister'),
  loading: Loading
})

const AgentDashboard = Loadable({
  loader: () => import('layouts/AgentDashboard'),
  loading: Loading
})

const AgentSettings = Loadable({
  loader: () => import('layouts/AgentSettings'),
  loading: Loading
})

const AgentRules = Loadable({
  loader: () => import('layouts/AgentRules'),
  loading: Loading
})

const AgentFinances = Loadable({
  loader: () => import('layouts/AgentFinances'),
  loading: Loading
})

const AgentInventory = Loadable({
  loader: () => import('layouts/AgentInventory'),
  loading: Loading
})

const AgentOffices = Loadable({
  loader: () => import('layouts/AgentOffices'),
  loading: Loading
})

const AgentQualify = Loadable({
  loader: () => import('layouts/AgentQualify'),
  loading: Loading
})

const AgentReporting = Loadable({
  loader: () => import('layouts/AgentReporting'),
  loading: Loading
})

const AgentWarehouse = Loadable({
  loader: () => import('layouts/AgentWarehouse'),
  loading: Loading
})

const AgentForfeitures = Loadable({
  loader: () => import('layouts/AgentForfeitures'),
  loading: Loading
})

const AgentAlert = Loadable({
  loader: () => import('layouts/AgentAlerts'),
  loading: Loading
})

const Case = Loadable({
  loader: () => import('layouts/Case'),
  loading: Loading
})

const Beta = Loadable({
  loader: () => import('layouts/Beta'),
  loading: Loading
})

const AgentDefendant = Loadable({
  loader: () => import('layouts/AgentDefendant'),
  loading: Loading
})

const Lead = Loadable({
  loader: () => import('layouts/Lead'),
  loading: Loading
})

const Defendant = Loadable({
  loader: () => import('layouts/Defendant'),
  loading: Loading
})

const DefendantBeta = Loadable({
  loader: () => import('layouts/DefendantBeta'),
  loading: Loading
})

const DocumentUpload = Loadable({
  loader: () => import('layouts/DocumentUpload'),
  loading: Loading
})

const Documents = Loadable({
  loader: () => import('layouts/Documents'),
  loading: Loading
})

const Sign = Loadable({
  loader: () => import('layouts/Sign'),
  loading: Loading
})

const DefendantBondsman = Loadable({
  loader: () => import('layouts/DefendantBondsman'),
  loading: Loading
})

const PaymentInfo = Loadable({
  loader: () => import('layouts/PaymentInfo'),
  loading: Loading
})

const Quote = Loadable({
  loader: () => import('layouts/Quote'),
  loading: Loading
})

const PaymentPlan = Loadable({
  loader: () => import('layouts/PaymentPlan'),
  loading: Loading
})

const Questionnaire = Loadable({
  loader: () => import('layouts/Questionnaire'),
  loading: Loading
})

const PageAgentTerms = Loadable({
  loader: () => import('layouts/PageAgentTerms'),
  loading: Loading
})

const PageTerms = Loadable({
  loader: () => import('layouts/PageTerms'),
  loading: Loading
})

const PagePolicy = Loadable({
  loader: () => import('layouts/PagePolicy'),
  loading: Loading
})

const Releases = Loadable({
  loader: () => import('layouts/Releases'),
  loading: Loading
})

const Logout = Loadable({
  loader: () => import('layouts/Logout'),
  loading: Loading
})

const FormBuilder = Loadable({
  loader: () => import('layouts/FormBuilder'),
  loading: Loading
})

const Onboarding = Loadable({
  loader: () => import('layouts/Onboarding'),
  loading: Loading
})

const PaymentsDashboard = Loadable({
  loader: () => import('layouts/AgentPayments'),
  loading: Loading
})

const CheckinDashboard = Loadable({
  loader: () => import('layouts/CheckinDashboard'),
  loading: Loading
})

const PowerUploads = Loadable({
  loader: () => import('layouts/PowerUploads'),
  loading: Loading
})

const PasswordReset = Loadable({
  loader: () => import('layouts/PasswordReset'),
  loading: Loading
})

let routes = [
  {
    path: '/',
    exact: true,
    component: Home,
    isPrivate: false
  },
  {
    path: '/logout',
    exact: true,
    component: Logout,
    isPrivate: false
  },
  {
    path: '/example',
    exact: true,
    component: Example,
    isPrivate: false
  },
  {
    path: '/agent/activation',
    exact: true,
    component: Activation,
    isPrivate: true
  },
  {
    path: [
      '/learning-center/:section/:page',
      '/learning-center/:section',
      '/learning-center'
    ],
    exact: true,
    component: LearningCenter,
    isPrivate: false
  },
  {
    path: '/welcome/:onboardingToken',
    exact: true,
    component: Onboarding,
    isPrivate: false
  },
  {
    path: '/login',
    exact: true,
    component: Login,
    isPrivate: false
  },
  {
    path: [
      '/r/:token'
    ],
    exact: true,
    component: Receipt,
    isPrivate: false
  },
  {
    path: [
      '/employee/:token/reset'
    ],
    exact: true,
    component: PasswordReset,
    isPrivate: false
  },
  {
    path: [
      '/l/:slug'
    ],
    exact: true,
    component: MagicLink,
    isPrivate: false
  },
  {
    path: [
      '/e/:slug'
    ],
    exact: true,
    component: MagicEmployee,
    isPrivate: false
  },
  {
    path: [
      '/m/:slug/payment'
    ],
    exact: true,
    component: MagicPayment,
    isPrivate: false
  },
  {
    path: [
      '/m/:slug/office'
    ],
    exact: true,
    component: MagicCode,
    isPrivate: false
  },
  {
    path: [
      '/m/:slug/:location/:county',
      '/m/:slug'
    ],
    exact: true,
    component: Magic,
    isPrivate: false
  },
  {
    path: '/lookup/:token',
    exact: true,
    component: Lookup,
    isPrivate: false
  },
  {
    path: '/upload/:token',
    exact: true,
    component: SecureUpload,
    isPrivate: false
  },
  {
    path: [
      '/register/:countySlug',
      '/register'
    ],
    exact: true,
    component: Register,
    isPrivate: false
  },
  {
    path: '/releases',
    exact: true,
    component: Releases,
    isPrivate: false
  },
  {
    path: '/u/:token',
    exact: true,
    component: DefendantBeta,
    isPrivate: false
  },
  {
    path: [
      '/u/:caseId/plan/:paymentId'
    ],
    exact: true,
    component: MagicPlanPayment,
    isPrivate: false
  },
  {
    path: '/b/:token',
    exact: true,
    component: Defendant,
    isPrivate: false
  },
  {
    path: '/sign/:token',
    exact: true,
    component: MagicSign,
    isPrivate: false
  },
  {
    path: '/d/:token',
    exact: true,
    component: DocumentUpload,
    isPrivate: false
  },
  {
    path: [
      '/u/:token/documents'
    ],
    exact: true,
    component: Documents,
    isPrivate: false
  },
  {
    path: [
      '/u/:token/sign'
    ],
    exact: true,
    component: Sign,
    isPrivate: false
  },
  {
    path: '/u/:token/bondsman',
    exact: true,
    component: DefendantBondsman,
    isPrivate: false
  },
  {
    path: '/u/:token/paymentInfo',
    exact: true,
    component: PaymentInfo,
    isPrivate: false
  },
  {
    path: '/u/:token/quote',
    exact: true,
    component: Quote,
    isPrivate: false
  },
  {
    path: '/u/:token/payments',
    exact: true,
    component: PaymentPlan,
    isPrivate: false
  },
  {
    path: '/u/:token/questionnaire',
    exact: true,
    component: Questionnaire,
    isPrivate: false
  },
  {
    path: '/powers/:token',
    exact: true,
    component: PowerUploads,
    isPrivate: true
  },
  {
    path: [
      '/agent/checkins/checkins-light',
      '/agent/checkins/active',
      '/agent/checkins/appearances',
      '/agent/checkins/settings',
      '/agent/checkins/missing/checkins',
      '/agent/checkins/missing/appearances',
      '/agent/checkins/user/:checkinId',
      '/agent/checkins'
    ],
    exact: true,
    component: CheckinDashboard,
    isPrivate: true
  },
  {
    path: [
      '/agent/alpha/new',
      '/agent/alpha/:caseId',
      '/agent/alpha/:caseId/qualify',
      '/agent/alpha/:caseId/files',
      '/agent/alpha/:caseId/powers',
      '/agent/alpha/:caseId/powers/add',
      '/agent/alpha/:caseId/payments',
      '/agent/alpha/:caseId/payments/add',
      '/agent/alpha/:caseId/payments/edit',
      '/agent/alpha/:caseId/payments/restructure',
      '/agent/alpha/:caseId/finances',
      '/agent/alpha/:caseId/finances/add',
      '/agent/alpha/:caseId/finances/pay',
      '/agent/alpha/:caseId/finances/payment/:paymentId/associate',
      '/agent/alpha/:caseId/court-dates',
      '/agent/alpha/:caseId/court-dates/add',
      '/agent/alpha/:caseId/court-dates/:courtDateId',
      '/agent/alpha/:caseId/court-dates/statuses/add/exonerated',
      '/agent/alpha/:caseId/court-dates/:courtDateId/status/:type/add',
      '/agent/alpha/:caseId/court-dates/:courtDateId/statuses/:statusId',
      '/agent/alpha/:caseId/court-dates/add/power/:powerId',
      '/agent/alpha/:caseId/court-dates/power/:powerId/exonerate',
      '/agent/alpha/:caseId/checkin',
      '/agent/alpha/:caseId/checkin/add',
      '/agent/alpha/:caseId/wizard',
      '/agent/alpha/:caseId/defendant',
      '/agent/alpha/:caseId/forfeitures',
      '/agent/alpha/:caseId/indemnitors',
      '/agent/alpha/:caseId/indemnitors/add',
      '/agent/alpha/:caseId/indemnitor/:indemnitorId',
      '/agent/alpha/:caseId/imported-data',
      '/agent/alpha/:caseId/logs',
      '/agent/alpha/:caseId/settings',
      '/agent/alpha/:caseId',
      '/agent/alpha/'
    ],
    exact: true,
    component: AlphaDashboard,
    isPrivate: true
  },
  {
    path: [
      '/agent/dashboard/leads',
      '/agent/dashboard/customers',
      '/agent/dashboard/customers/new',
      '/agent/dashboard/customers/ready',
      '/agent/dashboard/customers/leads',
      '/agent/dashboard/customers/completed',
      '/agent/dashboard'
    ],
    exact: true,
    component: AgentDashboard,
    isPrivate: true
  },
  {
    path: [
      '/agent/payments/projections',
      '/agent/payments/plans',
      '/agent/payments/manage',
      '/agent/payments/missed',
      '/agent/payments/:type',
      '/agent/payments'
    ],
    exact: true,
    component: PaymentsDashboard,
    isPrivate: true
  },
  {
    path: '/agent/register',
    exact: true,
    component: AgentRegister,
    isPrivate: true
  },
  {
    path: [
      '/agent/settings',
      '/agent/settings/profile',
      '/agent/settings/employees',
      '/agent/settings/notifications',
      '/agent/settings/reinstate',
      '/agent/settings/importer',
      '/agent/settings/permissions',
      '/agent/settings/time-clock',
      '/agent/settings/landing-page'
    ],
    exact: true,
    component: AgentSettings,
    isPrivate: true
  },
  {
    path: '/agent/rules',
    exact: true,
    component: AgentRules,
    isPrivate: true
  },
  {
    path: [
      '/agent/finances',
      '/agent/finances/invoices',
      '/agent/finances/settlements',
      '/agent/finances/payments',
      '/agent/finances/transactions',
      '/agent/finances/refunds',
      '/agent/finances/refunds/:caseId',
      '/agent/finances/search',
      '/agent/finances/authorizations'
    ],
    exact: true,
    component: AgentFinances,
    isPrivate: true
  },
  {
    path: [
      '/agent/inventory',
      '/agent/inventory/sheets',
      '/agent/inventory/sheet/:sheetId',
      '/agent/inventory/employees',
      '/agent/inventory/settings',
      '/agent/inventory/reporting',
      '/agent/inventory/tbd',
      '/agent/inventory/manage',
      '/agent/inventory/duplicates',
      '/agent/inventory/transfer-bonds',
      '/agent/inventory/surety/:suretyId',
      '/agent/inventory/report/:reportId',
      '/agent/inventory/report/builder/:suretyId'
    ],
    exact: true,
    component: AgentInventory,
    isPrivate: true
  },
  {
    path: [
      '/agent/offices',
      '/agent/offices/processors',
      '/agent/offices/locations',
      '/agent/offices/forms',
      '/agent/offices/uploads'
    ],
    exact: true,
    component: AgentOffices,
    isPrivate: true
  },
  {
    path: [
      '/agent/case/:customerId',
      '/agent/case/:customerId',
      '/agent/case/:customerId/info',
      '/agent/case/:customerId/notes',
      '/agent/case/:customerId/indemnitor/:indemnitor_slug',
      '/agent/case/:customerId/files',
      '/agent/case/:customerId/logs',
      '/agent/case/:customerId/files/:indemnitor_slug'
    ],
    exact: true,
    component: Case,
    isPrivate: true
  },
  {
    path: [
      '/agent/lead/new',
      '/agent/lead/:leadId',
      '/agent/lead/:leadId/indemnitor/add'
    ],
    exact: true,
    component: Lead,
    isPrivate: true
  },
  {
    path: [
      '/agent/defendant/new',
      '/agent/defendant/:defendantId',
      '/agent/defendant/:defendantId/defendant'
    ],
    exact: true,
    component: AgentDefendant,
    isPrivate: true
  },
  {
    path: [
      '/agent/beta/new',
      '/agent/beta/:caseId',
      '/agent/beta/:caseId/qualify',
      '/agent/beta/:caseId/files',
      '/agent/beta/:caseId/powers',
      '/agent/beta/:caseId/powers/add',
      '/agent/beta/:caseId/payments',
      '/agent/beta/:caseId/payments/add',
      '/agent/beta/:caseId/payments/edit',
      '/agent/beta/:caseId/payments/restructure',
      '/agent/beta/:caseId/finances',
      '/agent/beta/:caseId/finances/add',
      '/agent/beta/:caseId/finances/pay',
      '/agent/beta/:caseId/finances/payment/:paymentId/associate',
      '/agent/beta/:caseId/court-dates',
      '/agent/beta/:caseId/court-dates/add',
      '/agent/beta/:caseId/court-dates/:courtDateId',
      '/agent/beta/:caseId/court-dates/statuses/add/exonerated',
      '/agent/beta/:caseId/court-dates/:courtDateId/status/:type/add',
      '/agent/beta/:caseId/court-dates/:courtDateId/statuses/:statusId',
      '/agent/beta/:caseId/court-dates/add/power/:powerId',
      '/agent/beta/:caseId/court-dates/power/:powerId/exonerate',
      '/agent/beta/:caseId/checkin',
      '/agent/beta/:caseId/checkin/add',
      '/agent/beta/:caseId/wizard',
      '/agent/beta/:caseId/defendant',
      '/agent/beta/:caseId/forfeitures',
      '/agent/beta/:caseId/indemnitors',
      '/agent/beta/:caseId/indemnitors/add',
      '/agent/beta/:caseId/indemnitor/:indemnitorId',
      '/agent/beta/:caseId/imported-data',
      '/agent/beta/:caseId/logs',
      '/agent/beta/:caseId/settings'
    ],
    exact: true,
    component: Beta,
    isPrivate: true
  },
  {
    path: [
      '/agent/warehouse',
      '/agent/warehouse/sections',
      '/agent/warehouse/settings',
      '/agent/warehouse/section/:sectionId/questions',
      '/agent/warehouse/section/:sectionId/question/:questionId'
    ],
    exact: true,
    component: AgentWarehouse,
    isPrivate: true
  },
  {
    path: [
      '/agent/reporting',
      '/agent/reporting/liability',
      '/agent/reporting/stats',
      '/agent/reporting/search',
      '/agent/reporting/templates',
      '/agent/reporting/template/:templateId',
      '/agent/reporting/ar-report'
    ],
    exact: true,
    component: AgentReporting,
    isPrivate: true
  },
  {
    path: [
      '/agent/alerts/all',
      '/agent/alerts'
    ],
    exact: true,
    component: AgentAlert,
    isPrivate: true
  },
  {
    path: [
      '/agent/forfeitures'
    ],
    exact: true,
    component: AgentForfeitures,
    isPrivate: true
  },
  {
    path: [
      '/agent/qualify'
    ],
    exact: true,
    component: AgentQualify,
    isPrivate: true
  },
  {
    path: '/terms',
    exact: true,
    component: PageTerms,
    isPrivate: false
  },
  {
    path: '/terms/agent',
    exact: true,
    component: PageAgentTerms,
    isPrivate: false
  },
  {
    path: '/privacy',
    exact: true,
    component: PagePolicy,
    isPrivate: false
  },
  {
    path: [
      '/form/:formId',
      '/form/:formId/d'
    ],
    exact: true,
    component: FormBuilder,
    isPrivate: false
  }
]

const RouteWithSubRoutes = (route) => (
  <Route path={route.path} render={props => (
    <route.component {...props} routes={route.routes} />
  )} />
)

const ProtectedRoute = ({ component, ...args }) => (
  <Route
    component={withAuthenticationRequired(component, {
      onRedirecting: () => {
        window.localStorage.setItem('redirectLocation', window.location.pathname)
        return <Loading text="Please Wait" />
      }
    })}
    {...args}
  />
)

const isPrivate = route => {
  let isPrivateRoute = route.isPrivate
  if (process.env.REACT_APP_NODE_ENV === 'local' && process.env.REACT_APP_LOCAL_USER) {
    isPrivateRoute = false
  }
  return isPrivateRoute
}

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <Redirect from="/agent/case/:caseId" to="/agent/beta/:caseId" />
      {routes.map((route, i) => (
        isPrivate(route) ? <ProtectedRoute key={i} {...route} /> : <RouteWithSubRoutes key={i} {...route} /> //  <PrivateRoute key={i} {...route} />
      ))}
      <Route path="/callback" render={(props) => {
        return <Callback {...props} />
      }}/>
      <Route component={NotFound} />
    </Switch>
  </BrowserRouter>
)

export default Routes
