import { action, observable, makeObservable } from "mobx"
import api from 'lib/api'

class DocumentStore {
  document = null
  documents = null

  constructor() {
    makeObservable(this, {
      document: observable,
      documents: observable,
      fetchUserDocuments: action.bound,
      updateUserDocuments: action.bound
    })
  }

  // Methods and Calls

  async fetchDocumentsUrl(token, params) {
    return api.documents.fetchDocumentsUrl(token, params)
  }

  async fetchUserDocuments(token) {
    this.document = await api.documents.fetchUserDocuments(token)
    return this.document
  }

  async fetchAllUserDocumentStatus(token) {
    return api.documents.fetchAllUserDocumentStatus(token)
  }

  async updateUserDocuments(token, params) {
    let results = await api.documents.updateUserDocuments(token, params)
    if (results) {
      return results
    }
  }

  async updateDocumentSubmission(token, params) {
    let results = await api.documents.updateDocumentSubmission(token, params)
    if (results) {
      return results
    }
    throw new Error('Could not update submission')
  }

  async fetchDeveloperData() {
    return api.documents.fetchDeveloperData()
  }
}

export default DocumentStore
