import { observable, action, makeObservable } from "mobx"


class UserStore {
  isLoggedIn = false
  userId = null
  accessToken = null
  idToken = null
  expiresAt = 0

  // auth0 = new auth0.WebAuth({
  //   domain: AUTH0_DOMAIN,
  //   clientID: AUTH0_KEY,
  //   redirectUri: AUTH0_CALLBACK,
  //   responseType: 'token id_token',
  //   scope: 'openid'
  // })

  // constructor() {
  //   // const tokenCheck = localStorage.getItem('token')
  //   // if (tokenCheck) {
  //   //   this.isLoggedIn = true
  //   //   this.userId = localStorage.getItem('authUserId')
  //   //   this.accessToken = localStorage.getItem('token')
  //   // }
  // }

  login = () => {
    // this.auth0.authorize()
  }

  constructor() {
    makeObservable(this, {
      isLoggedIn: observable,
      accessToken: observable,
      idToken: observable,
      expiresAt: observable,
      handleAuthentication: action.bound,
      setSession: action.bound,
      renewSession: action.bound,
      logout: action.bound,
      fetchProfile: action.bound
    })
  }

  getAccessToken() {
    return ''
    // return this.accessToken
  }

  getIdToken() {
    return ''
    // return this.idToken
  }

  handleAuthentication = async () => {
    // try {
    //   const authResult = await this.parseHash()
    //   const results = await this.setSession(authResult)
    //   return results
    // } catch(err) {
    //   throw err
    // }
  }

  setSession(authResult) {

    // Set isLoggedIn flag in localStorage
    // localStorage.setItem('isLoggedIn', 'true')
    // localStorage.setItem('token', authResult.accessToken)
    // localStorage.setItem('authUserId', authResult.idTokenPayload.sub)

    // // Set the time that the access token will expire at
    // let expiresAt = (authResult.expiresIn * 1000) + new Date().getTime()

    // if (new Date().getTime() < expiresAt) {
    //   this.userId = authResult.idTokenPayload.sub
    //   this.accessToken = authResult.accessToken
    //   this.idToken = authResult.idToken
    //   this.expiresAt = expiresAt
    //   this.isLoggedIn = true
    // } else {
    //   this.logout()
    // }

    // return Promise.resolve(true)
  }

  renewSession() {
    // this.auth0.checkSession({}, (err, authResult) => {
    //   if (authResult && authResult.accessToken && authResult.idToken) {
    //     console.log('authResult: ', authResult)
    //     this.setSession(authResult)
    //   } else if (err) {
    //     console.log('##### Error: ', err)
    //      this.logout()
    //      console.log(err)
    //      // alert(`Could not get a new token (${err.error}: ${err.error_description}).`)
    //   }
    // })
  }

  logout = () => {
    // this.userId = null
    // this.isLoggedIn = false
    // this.accessToken = null
    // this.idToken = null
    // this.expiresAt = 0
    // this.userProfile = null

    // localStorage.removeItem('isLoggedIn')
    // localStorage.removeItem('token')

    // history.replace('/')
  }

  // Auth0 Calls

  fetchProfile() {
    // return new Promise((resolve, reject) => {
    //   this.auth0.client.userInfo(this.accessToken, (err, profile) => {
    //     if (err) reject(err)
    //     return resolve(profile)
    //   })
    // })
  }

  parseHash() {
    // return new Promise((resolve, reject) => {
    //   this.auth0.parseHash((err, results) => {
    //     if (err) reject(err)
    //     return resolve(results)
    //   })
    // })
  }
}

export default UserStore
