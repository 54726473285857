// src/Callback/Callback.js

import React, { useEffect, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

import Loading from 'components/Loading'
import api from 'lib/api'

const Callback = props => {
  const [message, setMessage] = useState('Loading')

  const { history } = props

  const auth0 = useAuth0()

  const {
    isLoading,
    isAuthenticated,
    error,
    loginWithRedirect,
    getAccessTokenSilently
  } = auth0

  useEffect(() => {
    localStorage.removeItem('authUserId')
    localStorage.removeItem('token')
  }, [])

  useEffect(() => {    
    const _checkLogin = async () => {
      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: process.env.REACT_APP_AUTH0_AUDIENCE
          }
        })
        if (accessToken) {
          const results = await api.bondsman.auth({ token: accessToken })
          if (results) {

            if (results.loginType === 'SURETY') {
              window.location = process.env.REACT_APP_SURETY_URI
            } else if (results.loginType === 'PORTAL') {
              window.location = process.env.REACT_APP_PORTAL_URI
            } else {
              localStorage.setItem('authUserId', results.authUserId)
              localStorage.setItem('token', accessToken)
              let loc = window.localStorage.getItem('redirectLocation')
              if (loc) {
                window.localStorage.removeItem('redirectLocation')
                history.push(loc)
              } else {
                if (process.env.REACT_APP_SIMPLYBAIL_VERSION === "1" && process.env.REACT_APP_BUILD === 'production' && results.features.indexOf('MESSIAH') >= 0) {
                  window.location = `${process.env.REACT_APP_MESSIAH_URL}/callback`
                } else {
                  history.push('/agent/dashboard')
                }
              }
            }
            
          } else {
            history.push('/?error=Error')
          }
        } else {
          throw new Error('Token Payload is not valid.')
        }
      } catch(e) {
        console.log(e)
        // window.localStorage.setItem('redirectLocation', window.location.pathname)
        // window.location = '/logout'
      }
    }
    
    if (!isLoading) {
      if (error) {
        setMessage('There was an error with login.')
      } else if (!isAuthenticated) {
        setMessage('Redirecting to Login')
        loginWithRedirect()
      } else if (isAuthenticated) {
        setMessage('Success! Redirecting...')
        _checkLogin()
      }
    }
  }, [isLoading, isAuthenticated, error, getAccessTokenSilently, history, loginWithRedirect])

  return <Loading text={message} isError={error} />
}

export default Callback