import { observable, action, makeObservable } from "mobx"

import api from 'lib/api'

class FinancesStore {
  snapshot = null
  releasedFunds = null
  payments = null
  refunds = null
  transactions = null
  invoices = null
  stats = null

  constructor() {
    makeObservable(this, {
      stats: observable,
      releasedFunds: observable,
      payments: observable,
      refunds: observable,
      transactions: observable,
      invoices: observable,
      fetchSnapshot: action.bound,
      fetchPayments: action.bound,
      fetchRefunds: action.bound,
      fetchTransactions: action.bound,
      fetchInvoices: action.bound
    })
  }

  async fetchSnapshot() {
    const results = await api.finance.fetchSnapshot()
    if (results) {
      this.snapshot = results
      return this.snapshot
    }
    return null
  }

  async fetchPayments({ name = null, date = null, unAssignedFlag = false }) {
    const results = await api.finance.fetchPayments({ name, date, unAssignedFlag })
    if (results) {
      this.payments = results.payments
      return this.payments
    }
    return null
  }

  async fetchTransactions(date) {
    const results = await api.finance.fetchTransactions(date)
    if (results) {
      this.transactions = results.transactions
      return this.transactions
    }
    return null
  }

  async fetchRefunds() {
    const results = await api.finance.fetchRefunds()
    if (results) {
      this.refunds = results.refunds
      return this.refunds
    }
    return null
  }

  async fetchInvoices() {
    const results = await api.finance.fetchInvoices()
    if (results) {
      this.invoices = results.invoices
      return this.invoices
    }
    return null
  }
}

export default FinancesStore
