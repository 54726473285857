import React from 'react'

const Loading = props => {

  return (
    <div className="mask">
      <div className="mask__container">
        <p>{props.text || "Please wait"}</p>
        <div className="loader__container"><div className="loader centerLoader"><span></span></div></div>
      </div>
    </div>
  )
}

export default Loading