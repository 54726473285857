import { observable, action, makeObservable } from "mobx"

import api from 'lib/api'

class OfficesStore {
  bondsman = null
  offices = null
  files = null
  locations = null
  processors = null
  suretys = null

  constructor() {
    makeObservable(this, {
      offices: observable,
      files: observable,
      locations: observable,
      processors: observable,
      fetchFiles: action.bound,
      fetchOffices: action.bound,
      saveOffice: action.bound,
      removeOffice: action.bound,
      saveOfficeFile: action.bound,
      saveOfficeEmployee: action.bound,
      saveOfficeLocation: action.bound,
      removeOfficeAttribute: action.bound,
      saveBondsmanCounty: action.bound,
      saveBondsmanCountyRate: action.bound,
      removeBondsmanCounty: action.bound,
      updateOfficeSetting: action.bound
    })
  }

  setBondsman(bondsman) {
    this.bondsman = bondsman
  }

  async fetchOffices() {
    const results = await api.offices.fetchOffices()
    if (results) {
      this.offices = results.offices
      this.files = this.mapFiles(results.files)
      this.locations = results.locations
      this.processors = results.processors
      this.suretys = results.suretys
      return results
    }
    return null
  }

  async saveOffice(params) {
    let results = null
    if (params.officeId) {
      let officeId = params.officeId
      delete params.officeId
      results = await api.offices.updateOffice(officeId, params)
    } else {
      results = await api.offices.saveOffice(params)
    }
    if (results) {
      this.offices = results.offices
      return this.offices
    }
    return null
  }

  async removeOffice(params) {
    const results = await api.offices.removeOffice(params)
    if (results) {
      this.offices = results.offices
      return this.offices
    }
    return null
  }

  async saveOfficeFile(officeId, fileId) {
    const results = await api.offices.saveOfficeFile(officeId, { fileId })
    if (results) {
      this.offices = this.offices.map(itm => {
        if (itm.id === officeId) return results.office
        return itm
      })
      return this.offices
    }
    return null
  }

  async saveOfficeEmployee(officeId, employeeId) {
    const results = await api.offices.saveOfficeEmployee(officeId, { employeeId })
    if (results) {
      await this.fetchOffices()
      return this.offices
    }
    return null
  }

  async saveOfficeLocation(officeId, locationId) {
    const results = await api.offices.saveOfficeLocation(officeId, { locationId })
    if (results) {
      this.offices = this.offices.map(itm => {
        if (itm.id === officeId) return results.office
        return itm
      })
      return this.offices
    }
    return null
  }

  async removeOfficeAttribute(officeId, params) {
    const results = await api.offices.removeOfficeAttribute(officeId, params)
    if (results) {
      this.offices = this.offices.map(itm => {
        if (itm.id === officeId) return results.office
        return itm
      })
      return this.offices
    }
    return null
  }

  async updateOfficeSetting(officeId, params) {
    const results = await api.offices.updateOfficeSetting(officeId, params)
    if (results) {
      this.offices = results.offices
      return this.offices
    }
    return null
  }

  // Locations

  async saveBondsmanCounty(countyId) {
    try {
      const results = await api.bondsman.saveBondsmanCounty({ countyId })
      if (results) {
        this.locations = results

        // this.locations = results.filter(itm => itm.state !== 'OTHER')
        return this.locations
      }
      return null
    } catch(e) {
      console.error(e)
    }
  }

  async saveBondsmanCountyRate(locationId, rate) {
    try {
      const results = await api.bondsman.saveBondsmanCountyRate(locationId, { rate })
      if (results) {
        this.locations = results
        // this.locations = results.filter(itm => itm.state !== 'OTHER')
        return this.locations
      }
      return null
    } catch(e) {
      console.error(e)
    }
  }

  async removeBondsmanCounty(locationId) {
    try {
      const results = await api.bondsman.removeBondsmanCounty(locationId)
      if (results) {
        this.locations = results
        // this.locations = results.filter(itm => itm.state !== 'OTHER')
        return this.locations
      }
      return null
    } catch(e) {
      console.error(e)
    }
  }

  // Files & Documents

  async fetchFiles() {
    const results = await api.bondsman.fetchFiles()
    if (results) {
      this.files = this.mapFiles(results)
      return this.files
    }
    return null
  }

  mapFiles(items) {
    return items.map(item => {
      const doesExist = this.bondsman.settings.activeDocuments.find(itm => itm.id === item.id)
      if (doesExist) {
        item.isActive = doesExist.isActive
        item.isMandatory = doesExist.isMandatory
        item.isMandatoryDefendant = doesExist.isMandatoryDefendant
        item.name = doesExist.name
      } else {
        item.isActive = false
        item.isMandatory = false
        item.isMandatoryDefendant = false
        item.name = item.name || null
      }
      return item
    })
  }
}

export default OfficesStore
