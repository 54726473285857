import { observable, action, makeObservable } from "mobx"

import api from 'lib/api'

class WarehouseStore {
  constructor() {
    makeObservable(this, {
      agency: observable,
      sections: observable,
      questions: observable,
      fetchAgency: action.bound,
      setupAgency: action.bound,
      fetchAgencySections: action.bound
    })
  }

  // Observable
  agency = null
  sections = null
  questions = null

  // Events
  async fetchAgency(withVault = false) {
    const { agency } = await api.warehouse.fetchAgency(withVault)
    if (agency) {
      this.agency = agency
    }
    return this.agency
  }

  async setupAgency() {
    const { agency } = await api.warehouse.upsertAgency()
    if (agency) {
      this.agency = agency
      return this.agency
    }
    throw new Error('Could not create or update agency.')
  }

  async fetchAgencySections() {
    const { sections } = await api.warehouse.fetchSections()
    if (sections) {
      this.sections = sections
    }
    return this.sections
  }

  syncWarehouse() {
    return api.warehouse.syncWarehouse(this.agency.id)
  }
}

export default WarehouseStore
