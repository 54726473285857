import { action, observable, makeObservable } from 'mobx'
import { getSettings } from 'lib/settings-utils'
import api from '../lib/api'

class DefendantStore {
  currentPayments = null
  currentDefendant = null
  currentInvoices = null
  timeline = null

  constructor() {
    makeObservable(this, {
      currentDefendant: observable,
      currentInvoices: observable,
      currentPayments: observable,
      timeline: observable,
      register: action.bound,
      selectBondsman: action.bound,
      findDefendantWithToken: action.bound,
      fetchPayments: action.bound,
      savePaymentInfo: action.bound,
      setFinancesEnteredStatus: action.bound,
      confirmQuote: action.bound,
      confirmPaymentPlan: action.bound,
      fetchDefendantTimeline: action.bound,
      markQuestionaireFinished: action.bound,
      requestAlternateUpload: action.bound,
      updateSecondIndemnitor: action.bound,
      fetchDefendantBondSeeker: action.bound
    })
  }

  // Methods and Calls

  async register(params) {
    try {
      return api.user.register(params)
    } catch(e) {
      console.error(e)
    }
  }

  async fetchTokenizedUrl(token, text, type = 'indemnitor') {
    return api.files.fetchTokenizedUrl(token, text, type)
  }

  async fetchUploads(token) {
    return api.files.fetchUploads(token)
  }

  async fetchUrlsEmail(email) {
    return api.arrestee.fetchUrlsEmail({ email })
  }

  async createNotifyCustomer(variables) {
    return api.arrestee.createNotifyCustomer(variables)
  }

  async fetchDefendantTimeline(type) {
    if (this.currentDefendant) {
      return api.arrestee.fetchDefendantTimeline(this.currentDefendant.token, type)
    } else {
      throw new Error('Customer not selected.')
    }
  }

  async saveTimelineNote(type, params) {
    if (this.currentDefendant) {
      params.data = { noteTaker: { nameFirst: this.currentDefendant.user.nameFirst, nameLast: this.currentDefendant.user.nameLast } }
      return api.arrestee.saveTimelineNote(this.currentDefendant.token, type, params)
    } else {
      throw new Error('Customer not selected.')
    }
  }

  async findDefendantList(nameFirst, nameLast, countyId, is_beta = false) {
    try {
      const currentDefendantList = await api.lookup.victimSearch({ nameFirst, nameLast, countyId, is_beta })
      return currentDefendantList.map(itm => {
        itm.bookingTimestamp = +itm.bookingTimestamp
        itm.releaseTimestamp = +itm.releaseTimestamp
        return itm
      })
    } catch(e) {
      console.error(e)
    }
  }

  async findDefendantDetails(bookingNumber, nameFirst, nameLast, countyId, is_beta = false) {
    try {
      return api.lookup.victimSearchDetail({ bookingNumber, nameFirst, nameLast, countyId, is_beta })
    } catch(e) {
      console.error(e)
    }
  }

  async requestAlternateUpload(token) {
    try {
      const results = await api.arrestee.requestAlternateUpload(token)
      if (results) {
        this.currentDefendant = { ...this.currentDefendant, hasRequestedManualUpload: true }
        return this.currentDefendant
      }
    } catch(e) {
      console.error(e)
    }
  }

  async findDefendantWithToken(token, force = false) {
    try {
      if (this.currentDefendant && !force) {
        return this.currentDefendant
      }
      this.currentDefendant = await api.arrestee.fetchArrestee(token)

      if (this.currentDefendant.bondsman) {
        this.currentDefendant.bondsman.settings = getSettings(this.currentDefendant.bondsman.settings, this.currentDefendant.county.settings)
      }

      // this.currentDefendant.informationRequired.push('uploads')

      if (this.currentDefendant?.courthouses?.length > 0) {
        const { courtdates, checkin } = await api.bondsman.fetchCaseCheckinDocInfo(this.currentDefendant.bondsman.id, this.currentDefendant.id)
        if (courtdates?.length) {
          this.currentDefendant.courthouses = courtdates
        }
        if (checkin) {
          this.currentDefendant.checkin = checkin
        }
      }

      return this.currentDefendant
    } catch(e) {
      console.error(e)
    }
  }

  async fetchDefendantBondSeeker(token) {
    try {
      this.currentDefendant = await api.defendant.fetchDefendantBondSeeker(token)
      if (this.currentDefendant.bondsman) {
        this.currentDefendant.bondsman.settings = getSettings(this.currentDefendant.bondsman.settings, this.currentDefendant.county.settings)
      }
      return this.currentDefendant
    } catch(e) {
      console.error(e)
    }
  }

  async confirmQuote(token) {
    try {
      const results = await api.arrestee.confirmQuote(token)
      if (results) {
        this.currentDefendant = { ...this.currentDefendant, isQuoteConfirmed: true }
        return this.currentDefendant
      }
    } catch(e) {
      console.error(e)
    }
  }

  async confirmPaymentPlan(token) {
    try {
      const results = await api.arrestee.confirmPaymentPlan(token)
      if (results) {
        this.currentDefendant = { ...this.currentDefendant, isPaymentPlanConfirmed: true }
        return this.currentDefendant
      }
    } catch(e) {
      console.error(e)
    }
  }

  async selectBondsman(token, id, agentId = null) {
    try {
      this.currentDefendant = await api.arrestee.selectBondsman(token, { bondsmanId: id, employeeId: agentId })
      return this.currentDefendant
    } catch(e) {
      console.error(e)
    }
  }

  async searchBondsmenAgents(bondsmanId, countyId) {
    return api.arrestee.searchBondsmenAgents(bondsmanId, countyId)
  }

  async fetchPayments(token) {
    try {
      this.currentPayments = await api.arrestee.fetchPayments(token)
      this.currentPayments = this._mapPayments(this.currentPayments)
      return this.currentPayments
    } catch(e) {
      console.error(e)
    }
  }

  async createPaymentCustomer({ name, email }) {
    try {
      return api.arrestee.createPaymentCustomer(this.currentDefendant.token, { name, email })
    } catch(e) {
      console.error(e)
    }
  }

  async savePaymentInfo(params) {
    const results = await api.arrestee.savePaymentInfo(this.currentDefendant.token, params)
    if (results.payments) {
      this.currentPayments = this._mapPayments(results.payments)
    }
    return results
  }

  fetchQuestionnaire(token) {
    return api.arrestee.fetchQuestionnaire(token)
  }

  saveQuestionnaireAnswer(params) {
    return api.arrestee.saveQuestionnaireAnswer(this.currentDefendant.token, params)
  }

  async markQuestionaireFinished() {
    const results = await api.arrestee.markQuestionaireFinished(this.currentDefendant.token)
    if (results) {
      this.currentDefendant = { ...this.currentDefendant, ...results }
      return this.currentDefendant
    }
  }

  setFinancesEnteredStatus(financesEntered, status) {
    this.currentDefendant.financesEntered = financesEntered
    this.currentDefendant.status = status
  }

  async updateSecondIndemnitor(params) {
    const results = await api.arrestee.updateSecondIndemnitor(this.currentDefendant.token, params)
    if (results) {
      this.currentDefendant = { ...this.currentDefendant, secondIndemnitorContacted: results.secondIndemnitorContacted }
      return this.currentDefendant
    }
    throw new Error('Could not save information.')
  }

  // private

  _mapPayments(payments) {
    let newPayments = []
    if (payments.length > 0) {
      newPayments = payments.map(itm => {
        itm.amount = (itm.amount / 100)
        return itm
      })
    }
    return newPayments
  }

  _pullData(results, name) {
    if (results && results.data && results.data[name]) {
      return results.data[name]
    }
    throw new Error('Bad data.')
  }
}

export default DefendantStore
