import numeral from 'numeral' 
// import { toJS } from 'mobx'

export const getSettings = (settings, county) => {
  return { ...settings, ...county }
}

export const isActiveFeature = (bondsman, feature) => {
  const featureList = bondsman?.features || []
  return (featureList.indexOf(feature) >= 0)
}

export const isSettingValid = (settings, key) => {
  return (settings && settings[key])
}

export const getMinMax = (settings, key, type, defaultSetting) => {
  if (isSettingValid(settings, key)) {
    if (!settings[key].active) return defaultSetting
    if (type === 'MIN') return settings[key].min
    if (type === 'MAX') return settings[key].max
  }
  return defaultSetting
}

export const getFeeKeyActive = (settings, key, defaultSetting) => {
  if (isSettingValid(settings, key)) {
    return settings[key].active
  }
  return defaultSetting
}

export const getFeeKeyAddToBond = (settings, key, defaultSetting) => {
  if (isSettingValid(settings, key)) {
    if (!settings[key].active) return defaultSetting
    return settings[key].addToBondFee
  }
  return defaultSetting
}

export const getFeeKeyText = (settings, key, defaultSetting) => {
  if (isSettingValid(settings, key)) {
    if (!settings[key].active) return defaultSetting
    return settings[key].text
  }
  return defaultSetting
}

export const getFeeKeyAmount = (settings, key, defaultSetting) => {
  if (isSettingValid(settings, key)) {
    if (!settings[key].active) return defaultSetting
    return settings[key].setAmount
  }
  return defaultSetting
}

export const getFeesTotal = fees => {
  let totalDue = 0
  if (fees) {
    fees.forEach(itm => {
      if (!itm.isAddedToBond && !itm.bypassTotal && !itm.cashCollateral) {
        if (itm.isRebate) {
          totalDue = (totalDue - itm.amount)
        } else {
          totalDue = (totalDue + itm.amount)
        }
      }
    })
  }
  return totalDue
}

export const getBondAmountCalculation = (bond, fees, bondRate, getRateTotal = false) => {
  let totalDue = bond
  
  if (fees) {
    fees.forEach(itm => {
      if (itm.isAddedToBond) {
        if (itm.isRebate) {
          totalDue = (totalDue - itm.amount)
        } else {
          totalDue = (totalDue + itm.amount)
        }
      }
    })
  }

  if (getRateTotal) return totalDue
  return (totalDue * (bondRate / 100))
}

export const addFeeIfValid = (settings, total, key, value) => {
  if (!getFeeKeyAddToBond(settings, key)) { // If you dont add it to the bond then this is ok
    if (settings[key].isRebate) {
      return (total - value)
    }
    return (total + value)
  }
  return total
}

export const getTotalWithFiances = (breakdown) => {
  const _getAgentAmount = breakdown => {
    if (breakdown) {
      let { bondAmount, agentRate, fees } = breakdown
      return getBondAmountCalculation(bondAmount, fees, agentRate)
    }
    return 0
  }
  const _getAmountDue = breakdown => {
    let totalDue = 0
    if (breakdown) {
      const { collateralFeesAmount, fees } = breakdown
      const feesAmount = getFeesTotal(fees)
      totalDue = _getAgentAmount(breakdown)
      totalDue += feesAmount
      totalDue += parseFloat(collateralFeesAmount)
    }
    return totalDue
  }
  return _getAmountDue(breakdown)
}

// Take amount, add APR, and the $0.20 Transaction Fee 
export const calculateSurchargeAmount = (amount, apr) => {
  if (amount === 0) return 0
  let surchargeAmount = (Math.round((amount * (1 + (Number(apr)/100))) - amount) + 20)
  return (surchargeAmount < 0) ? 0 : surchargeAmount
}

export const formatMoney = val => {
  let amt = numeral((val / 100)).format('0,0.00')
  if ((val / 100) < 100) {
    return amt
  }
  return amt.replace('.00', '')
}

export const getGaActiveOffices = (agency) => {
  if (agency) {
    const { employee_type, employee_offices: offices, employee_permissions: permissions } = agency

    let isGaOfficesActivated = false
    if (!['SUPER','OWNER'].includes(employee_type)) {
      const isGa = isActiveFeature(agency, 'GA')
      if (isGa) {
        if (isGa && offices?.length > 0) {
          isGaOfficesActivated = true
        }
      }
      return isGaOfficesActivated ? { isActive: true, offices, permissions } : null
    }
  }
  return null
}